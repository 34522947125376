@import "../../utils/styles";

.VideoSelectTypeScreen {
  max-width: 600px;
  &_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 20px;
    align-items: center;
    @media screen and (max-width: 600px){
      grid-template-columns: 1fr;
      justify-content: center;
      text-align: center;
    }
    .btn {
      padding: 13px 20px !important;
      font-size: 14px !important;
      font-weight: bold !important;
    }

    .danger {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.33;
      color: $red;
    }
  }
}