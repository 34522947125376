@import "../../utils/styles";

.record-option-container {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  outline: none;
  max-width: 250px;
  background-color: $background;
  svg {
    margin: auto;
  }
  .drop-down-header {
    fill: $red;
    display: grid;
    grid-template-columns: 2.5fr 1fr 0.7fr;;
    height: 45px;
    list-style: none;
    padding: 0;
    color: $red;
    width: 250px;
    border: solid 1.5px #ff3f3f;
    .selected{
      transform:rotate(180deg);
    }
    .header-value{
      margin-left: 5px;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
    background-color: $background;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  .drop-down-list-container{
    width: 250px;
    position: absolute;
    z-index: 100;
    .drop-down-list {
      background-color: $background;
      font-size: 14px;
      color: $red;
      font-weight: bold;
      border-radius: 10px;
      border: solid 1.5px $red;
      padding: 0;
      .list-item{
        fill: $red;
        display: grid;
        grid-template-columns: 2.5fr 1fr 0.7fr;
        margin: 5px;
        padding-left: 10px;
        span{
         display: flex;
          align-items: center;
        }
        height: 45px;
        list-style: none;
        &:hover{
          background-color: #fde8bc;
          cursor: pointer;
          border-radius: 10px;
        }
      }
    }
  }
}




