@import "../../../app/utils/styles";
.video {
  &__container {
    &__controls {
      .control-buttons {
        box-sizing: border-box;
        max-height: 45px;
        margin-top: 20px;
        .btn-red {
          fill: white;
          .stop-icon {
            font-size: 0;
            width: 25px;
            height: 25px;
            border-radius: 8px;
            background-color: white;
          }
          margin-bottom: 20px;
          height: 45px;
          width: 250px;
          padding-left: 0;
          padding-right: 0;
          display: flex;
          align-items: center;
          justify-content: space-around;
          background-color: $red;
          &:hover {
            background-color: orangered;
            box-shadow: 0 0 2px 2px $dark;
          }
        }
      }
    }
  }
}



