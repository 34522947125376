.CountdownCounter {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);
  display: flex;
  align-items: center;
  justify-content: center;
  &_wrap {
    color: #fff;
    font-size: 50vh;
  }
}