@import "../../../app/utils/styles";

.info_csv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 45%;
  @include addFont('NunitoSans-Bold');
  font-size: 14px;
  align-items: flex-start;
  text-align: left;
  color: $red;
  font-weight: bold;
  &__title {
    margin-left: 20px;
    font-size: 14px;
  }
  &__points {
    padding-left: 20px;
  }
  &__img_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      margin-bottom: 20px;
      width: 250px;
      height: 100px;
    }
  }
}
