@import "../../../app/utils/styles";

.form-wrapper {
  padding: 20px 37.2px 16.5px 40px;
  border-radius: 15px;
  border: solid 1.5px rgba(255, 189, 51, 0.5);
  background-color: #ffffff;
  margin-top: 20px;
 .text-center {
   @include addFont('NunitoSans-Bold');
   font-weight: bold;
   color: $dark;
 }
}