@import "../../../app/utils/styles";
.add_variables_step {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .btn-outline-blue {
    text-align: center;
    margin-top: 30px;
    padding-left: 27px;
    padding-right: 27px;
  }
  .add-leads-title {
    color: $blue;
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0 20px 0;
  }
  .container {
    display: flex;
    width: 100%;
    .info_csv {
      margin-left: 25px;
    }
    .upload_csv {
      @include addFont('NunitoSans-Bold');
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 45%;
      height: 297px;
      padding: 30px 0 30px;
      border-radius: 15px;
      border: dashed 2px $dark;
      .FileUpload_wrap{
        color: $dark;
        display: flex;
        min-height: 75%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .upload_title {
          padding-bottom: 15%;
        }
        .info-text {
          padding-top: 5%;
          font-size: 12px;
          @include addFont('NunitoSans-Bold');
          font-weight: bold;
        }
        .btn.btn-white{
          background-color: white;
          &:hover {
            background-color: $ocean;
          }
        }
      }
      &__title {
        font-size: 16px;
        line-height: 1.38;
        text-align: center;
      }
      input {
        display: none
      }
      &__input{
        @include addFont('NunitoSans-Bold');
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        line-height: 1.36;
        color: $dark;
        font-weight: bold;
        width: 200px;
        height: 45px;
        border-radius: 15px;
        border: solid 2px #393f4d;
        transition: 0.2s;
        &:hover{
          color: $white;
          background-color: $blue;
          border-color: $blue;
        }
      }
      &__description {
        @include addFont('NunitoSans-Bold');
        font-size: 14px;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
        color: $dark;
      }
    }

  }
  .title {
    @include addFont('NunitoSans-Bold');
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    color: $dark;
  }
}





