.LandingPageItem {
  list-style: none;
  margin: 15px 0 0;
  padding: 33px 51px 32px 25px;
  border-radius: 15px;
  border: solid 1.5px rgba(255, 189, 51, 0.5);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffbd33;
  &_edit {
    margin: 0;
    padding: 0;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;;
    line-height: 1.36;
    color: #393f4d;
    transition: 250ms ease-in-out opacity;
    &:hover {
      cursor: pointer;
      opacity: .7;
    }
  }
}