@import "../../utils/styles";

.AuthForm {
  background-color: $blue;
  padding: 26px 40px 42px;
  border-radius: 15px;
  margin: 0 auto;
  width: 100%;
  max-width: calc(450px - 80px);
  @include media(tablet) {
    min-width: initial;
  }

  @include media(mobile) {
    width: calc(100% - 40px);
    border-radius: 0;
    z-index: 1;
    padding: 10px 20px 50px;
  }

  &_title {
    color: $white;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  }
}
