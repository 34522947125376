@import "../../utils/styles/index";

.modal_confirmation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(57, 63, 77, 0.5);
  &__body{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 500px;
    height: 205.8px;
    margin: 35.4px 42.1px 0 0;
    padding: 39.6px 39.5px 16.2px 25px;
    border-radius: 15px;
    border: solid 1.5px $red;
    background-color: $white;
    &__close{
      position: absolute;
      width: 65px;
      height: 65px;
      margin: 0 0 156.3px 457.2px;
      padding: 28px;
      background-color: $red;
      cursor: pointer;
      right: -30px;
      top: -30px;
      font-size: 0;
      border-radius: 50%;
      border: none;
      &:before{
        position: absolute;
        content: '';
        left: 31px;
        top: 13px;
        width: 2px;
        height: 42px;
        background-color: $white;
        transform: rotate(45deg);
      }
      &:after {
        position: absolute;
        content: '';
        left: 31px;
        top: 13px;
        width: 2px;
        height: 42px;
        background-color: $white;
        transform: rotate(-45deg);
      }
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: $white;
        border: 1px solid $red;
      }
      &:hover:before {
        background-color: $red;
      }
      &:hover:after {
        background-color: $red;
      }
    }

    &__title {
        @include addFont('NunitoSans-Bold');
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: $dark;
    }
  }
}
