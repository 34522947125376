@import "../../../app/utils/styles";

.video {
  width: 100%;
  &__container {
    @include addFont('NunitoSans-Bold');
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    align-content: space-between;
    justify-content: space-around;
    .title {
      display: flex;
      align-items: center;
      color: $dark;
      font-size: 16px;
      font-stretch: normal;
      img{
        padding-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
    &__controls {
      .recording-progress{
        min-width: 50%;
        min-height: 150px;
      }
        .FileUpload_wrap{
          margin-top: 20px;
          color: $dark;
          display: flex;
          min-height: 75%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .upload_title {
            padding-bottom: 20px;
          }
          .info-text {
            align-self: baseline;
            font-size: 12px;
            @include addFont('NunitoSans-Bold');
            font-weight: bold;
          }
          .btn.btn-white{
            background-color: white;
            &:hover {
              background-color: $ocean;
            }
          }

      }
    }
    &__player {
      .library-icon{
        margin-right: 10px;
      }
      display: grid;
      .video-description{
        font-size: 14px;
        font-weight: bold;
        color: $dark;
      }
      .btn-blue {
        margin-top: 5px;
        padding-left: 0;
        padding-right: 0;
        width: 250px;
      }
    }
  }
}



.btn-red-outline{
  background-color: $red;
}
