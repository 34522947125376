@import "../../utils/styles";

.Main {
  min-height: fit-content;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "nav header"
                       "nav main"
                       "nav footer";
  @include media(tablet) {
    grid-template-areas: "header header"
                         "nav main"
                         "footer footer";
  }

  @include media(mobile) {
    grid-template-areas: "header"
                         "main"
                         "footer";
    grid-template-columns: 1fr;
  }

  header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 50px 30px 35px;
    background-color: $background;
    @include media(mobile) {
      box-shadow: 0 2px 5px rgba($dark, .6);
      background-color: $white;
      z-index: 10;
      padding: 35px 15px;
      .welcome {
        display: none;
      }
    }

    .mobMenuBtn {
      display: none;
      background-color: $dark;
      border-radius: 5px;
      width: 40px;
      height: 6px;
      position: relative;
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      &:focus, &:active, &:disabled {
        outline: none;
        border: none;
      }

      &:after, &:before {
        transition: 300ms transform ease-in-out, 200ms top ease-in-out;
        position: absolute;
        left: 0;
        right: 0;
        height: inherit;
        width: inherit;
        border-radius: inherit;
        background-color: inherit;
        content: '';
      }

      &:before {
        top: -12px;
      }

      &:after {
        bottom: -12px;
      }

      &:hover {
        cursor: pointer;
      }

      &.opened {
        background-color: transparent;
        &:before, &:after {
          top: 0;
          background-color: $dark;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      @include media(mobile) {
        display: block;
      }
    }

    .welcome {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: $dark;
      font-family: 'Nunito Sans', sans-serif;
    }

    .logo {
      display: none;
      width: 150px;
      height: auto;
      @include media(tablet) {
        display: block;
      }
    }
  }

  &_nav {
    grid-area: nav;
    background-color: $white;
    padding: 30px 0 30px 60px;
    min-width: 300px;
    @include media(mobile) {
      position: absolute;
      left: 0;
      top: 110px;
      bottom: 0;
      z-index: 0;
      transform: translateX(-100%);
      transition: 300ms transform ease-in-out;
      &.showMobMenu {
        z-index: +99;
        transform: translateX(0);
      }
    }

    .logo {
      width: 150px;
      height: auto;
      margin: 0 auto 45px;
      @include media(tablet) {
        display: none;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: $dark;
        margin-bottom: 10px;
        a, .disabled {
          display: flex;
          align-items: center;
          color: inherit;
          font-size: inherit;
          letter-spacing: inherit;
          text-decoration: none;
          line-height: inherit;
          font-weight: inherit;
          font-stretch: inherit;
          text-align: inherit;
          transition: 300ms all ease-in-out;
          padding: 10px 50px 10px 20px;
          margin-left: -20px;
          white-space: nowrap;
          border-radius: 10px 0 0 10px;
          width: calc(100% - 70px);
          &:hover {
            color: $grey-text;
          }

          &.active {
            background-color: $blue-light;
            margin-left: 0;
          }

          .icon {
            max-width: 25px;
            min-width: 25px;
            font-style: normal;
            margin-right: 20px;
            color: $blue;
            line-height: inherit;
            font-size: inherit;
          }
        }

        .disabled {
          color: $grey-text;
          position: relative;
          .icon {
            color: inherit !important;
          }

          .comingSoon {
            color: inherit;
            font-size: 10px;
            font-weight: 600;
            max-width: 40px;
            font-style: italic;
            line-height: 1.4;
            letter-spacing: normal;
            position: absolute;
            right: 0;
            top: 10px;
            white-space: pre-line;
          }
        }
      }
    }
  }

  main {
    grid-area: main;
    padding: 15px 50px 15px 35px;
    background-color: $background;
    min-height: calc(100vh - 230px);
  }

  footer {
    grid-area: footer;
    background-color: $azure;
    padding: 30px 35px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      li:not(:last-child) {
        margin-right: 30px;
      }
      li {
        a {
          display: block;
          text-decoration: none;
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
          font-style: inherit;
          &:hover {
            color: $grey-text;
          }
        }
      }
    }
  }

  .menuLoader {
    font-size: 24px;
    padding: 1rem 0;
  }
}
