@import "../../utils/styles";

.CampaignsList {
  &_category {
    margin: 0 0 25px;
    &__title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      color: $dark;
      margin-bottom: 15px;
    }

    &__list {
      margin: 0;
      padding: 0;
      max-width: 650px;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
    }

    .CampaignListItem {
      width: 100%;
      background-color: $white;
      height: 85px;
      padding: 0 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 15px;
      border: solid 1.5px $orange-border;
      &_title {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.36;
        color: $orange;
        overflow: hidden;
        max-width: 200px;
        max-height: 60px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &_csv {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.36;
        color: $blue;
      }

      &_toggle {
        position: relative;
        width: 56px;
        height: 26px;
        border-radius: 36px;
        border: solid 2px $orange-border;
        &:hover, *:hover {
          cursor: pointer;
        }

        input[type="checkbox"] {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          z-index: 2;
          &:checked {
            &~.switch {
              background-color: $green;
              left: calc(100% - 30px);
            }
          }
        }

        .switch {
          z-index: 1;
          display: block;
          width: 28px;
          height: 24px;
          border-radius: 36px;
          background-color: $red;
          position: absolute;
          left: 2px;
          top: calc(50% - 12px);
          transition: 250ms ease-in-out left;
        }
      }

      &_action {
        display: flex;
        align-items: center;
        button, a {
          text-decoration: none;
          background-color: transparent;
          border: none;
          outline: none;
          padding: 0;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.36;
          color: $dark;
          &:hover {
            cursor: pointer;
          }

          &>span {
            text-decoration: underline;
          }
        }
      }

      a, button {
        transition: 250ms ease-in-out opacity;
        &:hover {
          opacity: .5;
        }
      }

      &.Inactive {
        border-color: #888f9e;
        * {
          color: #888f9e;
        }
      }
    }
  }
}
