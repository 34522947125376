@import "../../utils/styles";

.ToggledBlock {
  color: $white;
  background-color: $blue;
  position: relative;
  padding: 20px 30px 21px 20px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: calc(100% - 40px);
  &_close {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    &:hover {
      cursor: pointer;
    }
  }

  &_icon {
    max-width: 30px;
  }

  &_row {
    display: inline-flex;
    align-items: center;
    padding: 20px 30px;
    float: right;
    &>*:nth-child(2) {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  p {
    margin-bottom: 22px;
  }
}
