@import "../../utils/styles";

.drop-down-container {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    outline: none;
    .drop-down-header {
      svg{
       align-self: center;
      }
      .selected{
        transform:rotate(180deg);
      }
      display: flex;
      justify-content: space-between;
      min-width: 170px;
      max-width: fit-content;
      padding: 7px 10.4px 7px 12px;
      color: $dark;
      border-radius: 10px;
      border: solid 1.5px $dark;
      background-color: $white;
      &:hover {
        cursor: pointer;
        color: $dark;
      }
    }
    .drop-down-list-container{
      position: absolute;
      z-index: 100;
      .drop-down-list {
        min-width: 170px;
        padding: 7px 10.4px 7px 12px;
        color: $dark;
        border-radius: 10px;
        border: solid 1.5px $dark;
        background-color: $white;
        &:first-child {
          padding-top: 0.8em;
        }
        .list-item{
          padding: 0.4em 0 0.4em 0.4em;
          list-style: none;
          &:hover{
            cursor: pointer;
            background-color: $dark;
            color: $white;
            border-radius: 10px;
          }
        }
      }
    }
}




