@import "../../../app/utils/styles";
.video_wrapper {
  height: 100%;
  display: flex;
  position: relative;
  max-width: 500px;
  &__loading {
    position: absolute;
    top: 45%;
    left: calc(50% - 200px);
  }
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    border: dashed 1.5px $dark;
    min-height: 300px;
    max-height: 300px;
    width: 100%;
    .welcome-message-wrapper {
      -webkit-backdrop-filter: blur(27px);
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 100;
      backdrop-filter: blur(27px);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: rgba(0, 0, 0, 0.16);
      transform: rotate(-13deg);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 200px;
      height: 100px;
      border-radius: 100px / 50px;
      letter-spacing: 0.48px;
      &__text {
        color: $dark;
        font-family: "Caveat", "sans-serif";
        padding: 20px;
      }
    }
    &.active {
      border: solid 2px $orange;
    }

    .disabled {
      display: none;
      &~.play_button {
        display: none;
      }
    }
    .delete_button {
      display: flex;
      right: -25px;
      top: -20px;
      cursor: pointer;
      border-radius: 50%;
      border: none;
      background-color: transparent;
      position: absolute;
      &:focus {
        outline: none;
      }
    }
    .play_button{
      position: absolute;
      cursor: pointer;
      font-size: 0;
      width: 62px;
      height: 62px;
      background-color: transparent;
      border-radius: 50%;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
  .video {
    background-color: #000000;
    height: 100%;
    width: fit-content;
    max-width: 100%;
    border-radius: inherit;
    &:active, &:focus, &:hover {
      outline: none;
    }
  }
}
