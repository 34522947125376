@import "../../utils/styles";

.lead-video-list {
  width: 70%;
  ul{
    color: $blue;
    padding: 0;
  }
  list-style: none;
  .lead-video-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-decoration: underline;
    span {
      font-family: "Nunito Sans", "sans-serif";
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
  .edit-button {
    font-family: "Nunito Sans", "sans-serif";
    color: $dark;
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    height: 32px;
    width: 70px;
    .edit-icon {
      padding-right: 10px;
      width: 20px;
      height: 20px;
    }
    text-decoration: underline;
    background-color: $background;
    border: none;
    text-decoration-style: solid;
    text-decoration-color: black;
    outline: none;
  }
}
