//@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap');

@mixin addFont($name, $weight: 400, $style: normal) {
  @font-face {
    font-family: 'Nunito Sans';
    src: url("../../assets/fonts/" + $name + ".ttf");
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin addCaveatFont($name, $weight: 400, $style: normal) {
  @font-face {
    font-family: 'Caveat';
    src: url("../../assets/fonts/" + $name + ".ttf");
    font-weight: $weight;
    font-style: $style;
  }
}

@include addFont(NunitoSans-Black, 900);
@include addFont(NunitoSans-BlackItalic, 900, italic);
@include addFont(NunitoSans-Bold, 700);
@include addFont(NunitoSans-BoldItalic, 700, italic);
@include addFont(NunitoSans-ExtraBold, 800);
@include addFont(NunitoSans-ExtraBoldItalic, 800, italic);
@include addFont(NunitoSans-ExtraLight, 200);
@include addFont(NunitoSans-ExtraLightItalic, 200, italic);
@include addFont(NunitoSans-Italic, 400, italic);
@include addFont(NunitoSans-Light, 300);
@include addFont(NunitoSans-LightItalic, 300, italic);
@include addFont(NunitoSans-Regular);
@include addFont(NunitoSans-SemiBold, 600);
@include addFont(NunitoSans-SemiBoldItalic, 600, italic);
@include addCaveatFont(Caveat-Bold, 700)
