@import "../../utils/styles";

.FileUpload {
  display: flex;
  align-items: center;
  .btn {
    position: relative;
    max-width: 350px;
    &:hover {
      cursor: pointer;
    }

    input[type="file"] {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &:hover {
        cursor: pointer;
      }
    }

    &.uploaded {
      &:hover {
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($white, .2);
        }
      }
    }
  }

  &_preview {
    width: 100px;
    height: 50px;
    border: 1px dashed $dark;
    overflow: hidden;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
