.SupportPage {
  a {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    color: #393f4d;
    text-decoration: none;
    transition: 250ms ease-in-out opacity;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }
}