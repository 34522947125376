/*  Colors  */
$red: #ff3f3f;
$blue: #1f86ff;
$blue-light: #cae3ff;
$dark: #393f4d;
$grey-text: #707070;
$ocean: #50e3c2;
$white: #ffffff;
$azure: #def4c7;
$orange: #ffbd33;
$background: #fff8ea;
$green: #7ed321;
$orange-border: rgba(255, 189, 51, 0.5);

$bg-base: linear-gradient(to bottom, rgba(255, 255, 255, 0.23), rgba(255, 232, 183, 0.7) 54%, rgba(255, 189, 51, 0.15));
