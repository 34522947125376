@import "../../utils/styles/index";

.modal_container{
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(57, 63, 77, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__body{
    width: 500px;
    height: 205.8px;
    margin: 35.4px 42.1px 0 0;
    padding: 39.6px 39.5px 16.2px 25px;
    border-radius: 15px;
    border: solid 1.5px $orange;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: $white;
    position: relative;
    &__title {
      @include addFont('NunitoSans-Bold');
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      text-align: center;
      color: $dark;
    }
    &__buttons_wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      button{
        width: 230px;
        height: 45px;
        padding: 0;
      }
      .btn_confirm {
        background-color: $blue;
      }
    }
  }
}


.modal_confirmation{
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(57, 63, 77, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__body{
    width: 500px;
    height: 205.8px;
    margin: 35.4px 42.1px 0 0;
    padding: 39.6px 39.5px 16.2px 25px;
    border-radius: 15px;
    border: solid 1.5px $orange;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: $white;
    position: relative;
    &__title {
      @include addFont('NunitoSans-Bold');
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      text-align: center;
      color: $dark;
    }
    &__buttons_wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      button{
        width: 230px;
        height: 45px;
        padding: 0;
      }
      .btn_confirm{
        background-color: $blue;
      }
    }
  }
}
