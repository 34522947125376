@import "../../utils/styles";

.TextArea {
  display: flex;
  flex-direction: column;
  position: relative;
  .select-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }
  .insert-button {
    max-width: fit-content;
    min-width: 195px;
    padding: 7px 10.4px 7px 12px;
    font-size: 14px;
    font-weight: bold;
    max-height: 35px;
    text-align: center;
    color: $dark;
    outline: none;
    border-radius: 10px;
    border: solid 1.5px $dark;
    background-color: $white;
  }
  textarea {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    text-align: left;
    border: none;
    outline: none;
    resize: none;
    font-size: 14px;
    color: $dark;
    padding: 15px 0;
    border-bottom: 1px solid rgba($dark, .3);
    &::placeholder {
      color: $dark;
    }

    &::-webkit-scrollbar {
      width: .6em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }

  .counter {
    font-size: 11px;
    line-height: 1.36;
    color: $dark;
    position: absolute;
    right: 10px;
    top: 0;
  }
}
