@import "../../utils/styles";

.AuthFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: $dark;
  background-color: $azure;
  @include media(mobile) {
    position: initial;
    flex-direction: column;
    .AuthFooter_left, .AuthFooter_right {
      text-align: center;
      padding: 10px;
      &>* {
        margin: 0;
        padding: 0 10px;
      }
    }
  }

  &_left, &_right {
    display: flex;
    align-items: center;
    &>* {
      white-space: nowrap;
      margin-right: 20px;
      color: inherit;
      display: inherit;
      font-size: inherit;
      text-decoration: none;
    }
  }

  &_left {
    padding: 38px 0 38px 100px;
    text-align: left;
    @include media(tablet) {
      padding: 20px 0 20px 50px;
    }

    &>* {
      margin-right: 20px;
    }
  }

  &_right {
    padding: 38px 100px 38px 0;
    text-align: right;
    @include media(tablet) {
      padding: 20px 50px 20px 0;
    }

    &>* {
      margin-left: 20px;
    }
  }
}
