@import "../../utils/styles/index";

.progress-container {
  @include addFont('NunitoSans-Bold');
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 100%;
  color: $dark;
  .percent{
    padding-left: 20px
  }
  .text{
    padding-bottom: 22px;
  }
  .progress_wrapper {
    display: flex;
    align-items: center;
    .progress {
      border-radius: 15px;
      width: 309px;
      height: 10px;
      border: solid 1px $dark;
      background-color: $white;
      .progress-done {
        border-radius: 15px;
        border-right: 1.5px solid $dark;
        background: $ocean;
        height: 100%;
        transition: all 0.2s;
      }
    }
  }
}




