/*  Margins and paddings */
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);
@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.btn {
  $animationTime: 200ms;

  font-size: 14px;
  line-height: 1.36;
  color: $white;
  text-align: center;
  border-radius: 15px;
  background-color: $ocean;
  border: none;
  outline: none;
  padding: 14px 55px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  transition: $animationTime background-color ease-in-out, $animationTime color ease-in-out, $animationTime box-shadow ease-in-out;
  text-shadow: 0 0 1px rgba($dark, .5);
  text-decoration: none;
  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: $white;
    border-color: $ocean;
    color: $dark;
    box-shadow: 0 0 2px 2px $ocean;
    text-shadow: 0 0 1px rgba($white, .5);
  }

  &:disabled {
    opacity: 0.5;
  }

  &.btn {
    display: inline-block;
    &-block {
      display: block;
      width: 100%;
    }
    &-white {
      background-color: transparent;
      border: 2px solid $dark;
      color: $dark;
      text-shadow: 0 0 1px rgba($white, .5);
      &:hover:not(:disabled) {
        cursor: pointer;
        background-color: $ocean;
        color: $white;
        border: 2px solid $ocean;
        box-shadow: none;
        text-shadow: 0 0 1px rgba($dark, .5);
      }
    }
    &-danger {
      background-color: transparent;
      border: 2px solid $red;
      color: $red;
      text-shadow: none;
      &:hover:not(:disabled) {
        cursor: pointer;
        background-color: $red;
        color: $white;
        border: 1px solid $red;
        box-shadow: none;
        text-shadow: 0 0 1px 1px rgba($red, .5);
      }
    }
    &-outline {
      color: $ocean;
      background-color: $background;
      border: 2px solid $ocean;
      text-shadow: none;
      &:hover:not(:disabled):not(.btn-notHovered) {
        cursor: pointer;
        background-color: $ocean;
        border: 2px solid $ocean;
        color: $white;
        box-shadow: 0 0 1px 1px $white;
        text-shadow: none;
      }
      &-danger {
        color: $red;
        background-color: transparent;
        border: 2px solid $red;
      }
    }
    &-outline-blue {
      color: $blue;
      background-color: $white;
      border: 2px solid $blue;
      text-shadow: none;
      &:hover:not(:disabled):not(.btn-notHovered) {
        cursor: pointer;
        background-color: $blue;
        border-color: transparent;
        color: $white;
        box-shadow: 0 0 1px 2px $white;
        text-shadow: none;
      }
    }
    &-outline-dark {
      color: $dark;
      background-color: transparent;
      border: 2px solid $dark;
      text-shadow: none;
      &:hover:not(:disabled):not(.btn-notHovered) {
        cursor: pointer;
        background-color: $blue;
        border-color: transparent;
        color: $white;
        box-shadow: 0 0 1px 2px $white;
        text-shadow: none;
      }
    }
    &-blue {
      background-color: $blue;
      color: $white;
      &:hover:not(:disabled):not(.btn-notHovered) {
        cursor: pointer;
        background-color: $white;
        border-color: $blue;
        color: $blue;
        box-shadow: 0 0 1px 2px $blue;
        text-shadow: none;
      }
    }
  }

  &-pure {
    background-color: transparent;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.text {
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }

  &-black {
    color: $dark;
    font-family: 'Nunito Sans', sans-serif;
  }
  &-white {
    color: $white;
    font-family: 'Nunito Sans', sans-serif;
  }
  &-blue {
    color: $blue !important;
    font-family: 'Nunito Sans', sans-serif;
  }
  &-red {
    color: $red;
  }
  &-success {
    color: $green!important;
  }
}

.Title {
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  color: $blue;
}

.pageTitle {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: $dark;
  display: flex;
  align-items: center;
}

.pageSubTitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: $dark;
}

.stepTitle {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $dark;
  &>img {
    margin-right: 10px;
    max-height: 22px;
    width: auto;
  }
}

.d {
  &-none {
    display: none;
  }

  &-block {
    display: block;
  }

  &-flex {
    display: flex;
  }
}
.delete_scv {
  position: relative;
  left: 40px;
  bottom: 15px;
  cursor: pointer;
  font-size: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 2px $dark;
  background-color: $white;
  transition: background-color 0.2s;
  &:before{
    position: absolute;
    content: '';
    left: 12px;
    top: 5px;
    width: 2px;
    height: 16px;
    background-color: $dark;
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    content: '';
    left: 12px;
    top: 5px;
    width: 2px;
    height: 16px;
    background-color: $dark;
    transform: rotate(-45deg);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $red;
  }
  &:hover:before {
    background-color: $white;
  }
  &:hover:after {
    background-color: $white;
  }
}

.delete_file {
  position: absolute;
  cursor: pointer;
  right: -10px;
  top: -10px;
  font-size: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 2px #393f4d;
  background-color: #ffffff;
  transition: background-color 0.2s;
  &:before{
    position: absolute;
    content: '';
    left: 12px;
    top: 5px;
    width: 2px;
    height: 16px;
    background-color: $dark;
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    content: '';
    left: 12px;
    top: 5px;
    width: 2px;
    height: 16px;
    background-color: $dark;
    transform: rotate(-45deg);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $red;
  }
  &:hover:before {
    background-color: $white;
  }
  &:hover:after {
    background-color: $white;
  }
}

.upload_video  {
  @include addFont('NunitoSans-Bold');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 300px;
  padding: 30px 0 30px;
  border-radius: 15px;
  border: dashed  2px $dark;
  background-color: $background;
  .text-center {
    @include addFont('NunitoSans-Bold');
    font-size: 12px;
    color: $dark;
    font-weight: bold;
  }
}

.modal_confirmation_close {
    font-size: 0;
    width: 65px;
    height: 65px;
    margin: 0 0 155px 455px;
    padding: 28px;
    background-color: #ffbd33;
    cursor: pointer;
    right: -30px;
    top: -30px;
    position: absolute;
    border-radius: 50%;
    border: none;
    &:before{
      position: absolute;
      content: '';
      left: 31px;
      top: 13px;
      width: 2px;
      height: 42px;
      background-color: $white;
      transform: rotate(45deg);
    }
    &:after {
      position: absolute;
      content: '';
      left: 31px;
      top: 13px;
      width: 2px;
      height: 42px;
      background-color: $white;
      transform: rotate(-45deg);
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $red;
    }
    &:hover:before {
      background-color: $white;
    }
    &:hover:after {
      background-color: $white;
    }
}

.bg {
  &-player {
    background-color: $white;
    padding: 26.5px 36px 26.5px 25px;
    border-radius: 15px;
    border: solid 1.5px $orange;
  }
}

.defaultIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-items {
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
  &-center {
    align-items: center;
  }
}

.justify-content {
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
}

.position {
  &-relative {
    position: relative;
  }

  &-absolute {
    position: absolute;
  }
}