@import "./utils/styles/index";

.App {
  min-width: 100%;
  min-height: 100vh;
  position: relative;
  @include baseBg();

  @include media(mobile) {
    width: 100vw;
    height: 100vw;
  }
}
