$tablet-size: 920px;
$mobile-size: 600px;

@mixin background($imgpath,$position:0 0,$repeat: no-repeat) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
  }
}

@mixin baseBg() {
  background: $bg-base;
}

@mixin media ($breakpoint) {
  @if $breakpoint==tablet {
    @media only screen and (max-width: $tablet-size) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: $mobile-size) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media only screen and (min-width: $tablet-size) {
      @content;
    }
  }
}
