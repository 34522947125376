@import "../../utils/styles";

.AuthHeader {
  padding: 35px 100px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media(tablet) {
    padding: 20px 50px 0;
  }

  @include media(mobile) {
    background-color: $white;
    box-shadow: 0 1px 5px rgba($dark, .6);
    padding-bottom: 10px;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    display: block;
  }

  a.routeBtn {
    @include media(mobile) {
      display: none;
    }
  }
}
