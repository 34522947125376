@import "../../utils/styles";

.form-control {
  width: 100%;
  position: relative;
  &-input {
    background-color: $white;
    padding: 2px;
    border-radius: 15px;
    input:not([type="checkbox"]) {
      padding: 13px 18px;
      border: none;
      border-radius: 15px;
      box-sizing: border-box;
      width: 100%;
      color: inherit;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      text-align: left;
      &::placeholder {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        text-align: left;
        color: $grey-text;
      }
      &:active, &:focus {
        border: none;
        outline: none;
      }
    }

    &-error {
      input {
        border: 2px dashed $red !important;
        line-height: 14px!important;
      }
    }

    &.input-checkbox {
      background-color: transparent;
      label {
        position: relative;
        display: flex;
        align-items: center;
        color: $white;
        input {
          position: relative;
          width: 20px;
          height: 20px;
          margin: 0 10px 0 5px;
          z-index: 1;
          &:hover {
            cursor: pointer;
          }

          &:after {
            z-index: -1;
            content: '';
            position: absolute;
            left: -5px;
            top: -5px;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            background-color: $white;
            border-radius: 50%;
          }

          &:before {
            opacity: 0;
            transition: 150ms opacity ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $blue;
            border-radius: 50%;
            z-index: 1;
            content: '';
          }

          &:checked {
            &:before {
              opacity: 1;
            }
          }
        }

        a {
          color: inherit;
          transition: 200ms opacity ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
      }

      &.form-control-input-error {
        label input:before {
          left: -1px;
          top: -1px;
          opacity: 1;
          border: 1px dashed $red;
          background-color: transparent;
        }
        &+.form-error {
          margin-left: 30px;
        }
      }
    }
  }

  .form-error {
    position: absolute;
    bottom: -1px;
    transform: translateY(100%);
    color: $white;
    margin: 5px 18px 0;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
  }
}
