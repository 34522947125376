@import "../../utils/styles";

.Input {
  display: flex;
  position: relative;
  &_counter {
    font-size: 11px;
    line-height: 1.36;
    color: $dark;
    position: absolute;
    right: 0;
    top: 0;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    text-align: left;
    color: $dark;
    border: none;
    outline: none;
    padding: 10px 0;
  }
}
