@import "../../../app/utils/styles";

.leads-container {
  width: 100%;
  &__tab{
    width: fit-content;
    thead {
      padding-bottom: 11px;
    }
    th{
      width: fit-content;
      padding-right: 20px;

    }
    border-collapse: collapse;
    tr{
      border-bottom: 1px solid #393f4d;
    }
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    min-width: fit-content;
    height: fit-content;
    span {
      padding: 20px;
    }
    &__header{
      th {
        padding-bottom: 13px;
      }
      font-family: "Nunito Sans", sans-serif;
      text-align: left;
      min-height: 40px;
      font-weight: bold;
      color: #393f4d;
      line-height: 1.36;
      border-bottom: solid 2px #393f4d;
      .control-col{
        visibility: hidden;
      }
    }
    &__row{
      font-family: "Nunito Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      color: #393f4d;
      letter-spacing: normal;
      text-align: left;
      height: 40px;
      width: 100%;
      padding-top: 20px;
      border-bottom: 1px solid #888f9e;
      td {
        box-sizing: content-box;
        min-width: 120px;
        padding-right: 20px;
      }
      button {
        transition: 250ms ease-in-out opacity;
        &:hover {
          opacity: .5;
        }
      }
      &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          height: 40px;
          text-decoration: none;
          background-color: transparent;
          border: none;
          outline: none;
          padding: 0;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: $dark;
          &:hover {
            cursor: pointer;
          }
          &>span {
            text-decoration: underline;
          }
        }
      }
      input[type="text"] {
        font-family: "Nunito Sans", sans-serif;
        outline: none;
        font-size: 14px;
        height: 32px;
        max-width: 100px;
        border-radius: 8px;
        border: solid 1px #707070;
        background-color: #ffffff;
        padding: 0 0 0 5px;
      }
      .save-button{
        cursor: pointer;
        outline: none;
        width: 100px;
        height: 32px;
        margin: 0 4px 0 0;
        padding: 7px 34px 6px 35px;
        border-radius: 8px;
        border: solid 1px $blue;
        background-color: #ffffff;
        transition: background-color 0.2s;
        color: $blue;
        &:hover{
          background-color: $blue;
          color: white;
          opacity: 1;
        }
      }
    }
  }
  .add-line-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    &>span {
      text-decoration: underline;
    }
    .add-line-icon {
      transform: rotate(45deg);
      margin-right: 10px;
      g {
        fill: $background;
      }
    }
    background-color: $background;
    border: none;
    text-decoration-style: solid;
    text-decoration-color: black;
    outline: none;
      transition: 250ms ease-in-out opacity;
      &:hover {
        opacity: .5;
    }
  }

  &__btn-wrapper{
    .add-new-leads-input {
      display: none;
    }
    .btn{
      padding-left: 0;
      padding-right: 0;
    }
    .add-new-csv{
      box-sizing: border-box;
      input{
        display: none;
      }
    }
    .btn.btn-outline-blue{
      max-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
    }
    margin-top: 50px;
    max-width: 250px;
    display: flex;
    flex-direction: column;
  }
}
