@import "../../utils/styles";

.CampaignVideosLeadSteps {
  &_text {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    color: $blue;
    padding: 20px;
    max-width: 400px;
    background-color: $white;
    border-radius: 15px;
    border: solid 1.5px $dark;
  }

  &_steps {
    &__line {
      position: relative;
      width: 250px;
      height: 10px;
      border-radius: 15px;
      border: solid 1.5px #393f4d;
      &>span {
        position: absolute;
        left: 0;
        top: -0.015px;
        height: 100%;
        width: 10%;
        transition: 250ms ease-in-out width;
        background: #50e3c2;
        border-radius: 15px;
      }
    }

    &__count {}
  }
}