@import "../../utils/styles";

.NavDropdown {
  z-index: 1;
  position: relative;
  &_btn {
    padding: 5px 10px !important;
  }

  &_wrap {
    list-style: none;
    margin: 0;
    background-color: $white;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: calc(100% + 20px);
    padding: 0 13px 0 18px;
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    max-height: 0;
    transition: 300ms ease-in-out max-height, 500ms ease-in-out padding;
    &.active {
      max-height: 1000px;
      padding: 12px 13px 20px 18px;
    }

    &__item {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &>* {
        display: grid;
        align-content: center;
        align-items: center;
        grid-template-columns: 22px 1fr;
        grid-column-gap: 20px;
        text-decoration: none;
        color: inherit;
        border: none;
        outline: none;
        background-color: transparent;
        text-align: left;
        margin: 0;
        padding: 0;
        &:hover, &:focus, &:active {
          border: none;
          outline: none;
        }
      }

      button {
        &:hover {
          cursor: pointer;
        }
      }


      &.disabled {
        color: #888f9e;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
      }

      .notActive {
        grid-template-columns: 22px 1fr 38px;
        grid-column-gap: 0;
        .text {
          padding-left: 20px;
          padding-right: 14px;
        }
      }

      .comingSoon {
        font-size: 10px;
        font-weight: 600;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        width: 38px;
        max-width: 40px;
      }
    }
  }
}
