@import "./../../utils/styles";

.Auth {
  @include media(mobile) {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  &_content {
    padding: 50px 100px 100px;
    @include media(mobile) {
      padding: 0;
      height: 100%;
      display: flex;
    }

    &__link {
      display: block;
      color: $white;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
    }
  }

  &_form {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .signUp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    @include media(mobile) {
      .Auth_form__row {
        flex-direction: column;
        margin-bottom: 0 !important;
        .form-control {
          margin-right: 0 !important;
          margin-bottom: 25px;
        }
      }
    }

    .AuthForm {
      margin: 0;
    }

    &_comment {
      margin-left: 76px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: $dark;
      @include media(tablet) {
        display: none;
      }
      
      &__img {
        margin-bottom: 35px;
        img {
          display: block;
          margin: 0 auto;
          overflow: hidden;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          object-fit: contain;
          background-color: $dark;
        }
      }

      &__text {
        max-width: 360px;
      }

      &__info {
        font-style: italic;
      }
    }
  }
}
