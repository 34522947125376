.CampaignTabs {
  width: 100%;
  border-bottom: 1.5px solid #393f4d;
  display: flex;
  a {
    min-width: 150px;
    text-decoration: none;
    text-transform: capitalize;
    padding: 15px 10px;
    border-left: solid 1.5px #393f4d;
    border-top: solid 1.5px #393f4d;
    border-right: solid 1.5px #393f4d;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    color: #393f4d;
    background-color: #fff;
    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border: solid 1.5px #393f4d;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #50e3c2;
    }

    &.active {
      pointer-events: none;
      &~a {
        background-color: transparent;
        &:before {
          background-color: #fff;
        }
      }
    }

    &.disabled {
      &:hover {
        cursor: initial;
      }
    }
  }
}